<template>
  <div class="login">
    <div class="container-fluid px-1 px-md-5 px-lg-1 px-xl-5 py-5 mx-auto">
      <div class="card card0 border-3" style="border-radius:10px;">
        <div class="row d-flex">
          <div class="col-lg-7">
            <div class="card1 pb-5">
              <div class="row">
                <img src="/logo.png" class="logo" />
              </div>
              <div class="row px-3 justify-content-center mt-4 mb-5">
                <img src="/nuta.png" class="image" style="padding-top:100px" />
              </div>
            </div>
          </div>
          <div class="col-lg-5 border-line">
            <div class="card2 card border-0 " style="margin:30% 5% 30% 5%">
              <label class="mb-1">
                <b><h6 class="mb-0 text-lg">Menyiapkan Data {{Number((progressBarPercent).toFixed(0))}} %</h6></b>
              </label>
              <div class="progress" style="height: 16px;margin-top:20px;border-radius:10px;">
                <div
                  class="
                    progress-bar progress-bar-striped progress-bar-animated
                    bg-success
                  "
                  role="progressbar"
                  aria-valuenow="75"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  v-bind:style="{ width: progressBarPercent + '%' }"
                ></div>
              </div>
              <label class="text-center" >
                  Sedang {{proses}}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";

const file = [
            "downloadtotaldownload",
            "downloadoptions",
            "downloadCategory",
            "downloadsatuan",
            "downloadProduct",
            "downloaditemingredients",
            "downloadcashbank",
            "downloadsupplier",
            "downloadcustomer",
            "downloadwaitress",
            "downloadvariant",
            "downloadmastertax",
            "downloadmasterdiscount",
            "downloadmodifier",
            "downloadmodifierdetail",
            "downloadmodifierdetailingredients",
            "downloadmasteritemdetailmodifier",
            "downloadmasterdiningtable",
            "downloadmasterarea",
            "downloadmasteropsimakan",
            "downloadmasterpromo",
            "downloadmasterdigitalpayment",
            "downloadusertablet",
            "downloadcashbankin",
            "downloadcloudcashbankin",
            "downloadcashbankout",
            "downloadcloudcashbankout",
            "downloadpurchase",
            "downloadpurchaseitemdetail",
            "downloadpendingsale",
            "downloadpendingsaleitemdetail",
            "downloadpendingsaleitemdetailingredients",
            "downloadpendingsaleitemproduct",
            "downloadpendingsaleitemdetailmodifier",
            "downloadpendingsalemodifierdetailingredients",
            "downloadpendingsaleitemdetailtax",
            "downloadstockopname",
            "downloadstockopnamedetail",
            "downloadopencloseoutlet",
            "downloadpendingsalediningtabledetail",
            "downloadpendingsalediscountdetail",
            "downloadlatesttransactionid",
            "downloadimageitems2",
            "downloadtglexpired",
            "downloadmasterdigitalpayment",
            "downloadmasterpricebytype",
            ];
  const namafile = [
            "medownload total download",
            "medownload options",
            "medownload Category",
            "medownload satuan",
            "medownload Product",
            "medownload itemingredients",
            "medownload cashbank",
            "medownload supplier",
            "medownload customer",
            "medownload waitress",
            "medownload variant",
            "medownload mastertax",
            "medownload masterdiscount",
            "medownload modifier",
            "medownload modifierdetail",
            "medownload modifier detail ingredients",
            "medownload master item detail modifier",
            "medownload master dining table",
            "medownload masterarea",
            "medownload masteropsimakan",
            "medownload master promo",
            "medownload masterdigital payment",
            "medownload user tablet",
            "medownload cashbankin",
            "medownload cloud cash bankin",
            "medownload cash bank out",
            "medownload cloud cash bank out",
            "medownload purchase",
            "medownload purchase item detail",
            "medownload pending sale",
            "medownload pending sale item detail",
            "medownload pending sale item detail ingredients",
            "medownload pending sale item product",
            "medownload pending sale item detail modifier",
            "medownload pending sale modifier detail ingredients",
            "medownload pending sale item detail tax",
            "medownload stock opname",
            "medownload stock opname detail",
            "medownload open close outlet",
            "medownload pending sale dining table detail",
            "medownload pending sale discount detail",
            "medownload latest transaction id",
            "medownload imageitems2",
            "medownload tgl expired",
            "medownload master digital payment",
            "medownload master markup bisa beda tiap produk",
            ];
export default {
  name: "DownloadData",
  data() {
    return {
      submitted: false,
      errors: null,
      progressBarPercent: 0,
      proses:"",
    };
  },
  computed: {},
  watch: {},
  methods: {
    ...mapActions(file),
    doDownload(){
        for (let index = 0; index < file.length; index++) {
          this.$store
            .dispatch(file[index])
            .then(() => {
              this.progressBarPercent += (100/file.length);
              this.proses= namafile[index];
              if(this.progressBarPercent >= 100){
                  this.$store
                  .dispatch("cekopenoutlet")
                  .then((result) => {
                      if (result==true){
                          this.$router.push({name: 'Kasir'})
                      }
                      else{
                          this.$router.push({name: 'Open Outlet'})
                      }
                  })
              } 
            })
            .catch((error) => {
              this.errors = error.statusText;
            });
        }    
    }
  },
  mounted() {
    this.doDownload();
  },
};
</script>

<style scoped>
body {
  color: #000;
  overflow-x: hidden;
  height: 100%;
  background-repeat: no-repeat;
}

.card0 {
  box-shadow: 0px 3px 8px 0px #757575;
  border-radius: 0px;
}

.card2 {
  margin: 0px 40px;
}

.logo {
  width: 150px;
  height:30px;
  transform: translateX(30px) translateY(21px);
}

.image {
  width: 650px;
  height: auto;
}

.border-line {
  border-left: 1px solid #eeeeee;
  margin-top:10px;
  margin-bottom:10px;
}

.or {
  width: 10%;
  font-weight: bold;
}

.text-sm {
  font-size: 14px !important;
}

::placeholder {
  color: #bdbdbd;
  opacity: 1;
  font-weight: 300;
}

:-ms-input-placeholder {
  color: #bdbdbd;
  font-weight: 300;
}

::-ms-input-placeholder {
  color: #bdbdbd;
  font-weight: 300;
}

input,
textarea {
  padding: 10px 12px 10px 12px;
  border: 1px solid rgb(148, 148, 148);
  border-radius: 2px;
  margin-bottom: 5px;
  margin-top: 2px;
  width: 100%;
  box-sizing: border-box;
  color: #000000;
  font-size: 14px;
  letter-spacing: 1px;
}

input:focus,
textarea:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid #00ae2b;
  outline-width: 0;
}

button:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline-width: 0;
}

a {
  color: inherit;
  cursor: pointer;
}

.btn-green {
  background-color: #00ae2b;
  width: 150px;
  color: #fff;
  border-radius: 2px;
}

.btn-green:hover {
  background-color: #019b28;
  cursor: pointer;
}

.bg-blue {
  color: #fff;
  background-color: #1a237e;
}
@media screen and (max-width: 991px) {
  .logo {
    margin-left: 0px;
  }

  .image {
    width: 300px;
    height: 220px;
  }

  .border-line {
    border-right: none;
  }

  .card2 {
    border-top: 1px solid #eeeeee !important;
    margin: 0px 15px;
  }
}
</style>